import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import Loader from 'react-loader-spinner';
import CookieConsent from "react-cookie-consent";
import './page.css';

class Page extends Component {
  render() {
    return (
      <div className="page">
          {this.props.pageData ? renderHTML(this.props.pageData.content.rendered) : <span className="loader"><Loader type="TailSpin" color="#00BFFF" height="100" width="100" /></span> }
          <CookieConsent
            location="bottom"
            buttonText="Es piekrītu!"
            acceptOnScroll={true}
            acceptOnScrollPercentage={50}
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
          Šī pala izmanto sīkdatnes('Cookies'). Turpinot lietot šo lapu, Jūs piekrītat, ka mēs uzkrāsim un izmantosim sīkdatnes Jūsu ierīcē.
          </CookieConsent>
      </div>
    );
  }
}

export default Page;
