import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import Loader from 'react-loader-spinner';

class Feed extends Component {
  render() {
    return (
      <div className="page">
          {this.props.pageData ?
            this.props.pageData.map( article => {
              return  renderHTML(article.content.rendered)
            })
            : <Loader className="loader" type="TailSpin" color="#00BFFF" height="100" width="100" /> }
      </div>
    );
  }
}

export default Feed;
