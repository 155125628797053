import React, { Component } from 'react';
import logo from '../../assets/logo.png'
import './header.css'

class Header extends Component {
  render() {
    return (
      <div className="header">
          <img className="headerLogo" src={logo} alt='DR Inta Kusina'/>
      </div>
    );
  }
}

export default Header;
